*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  outline: none;
}

*::-webkit-scrollbar{
  display: none;
}

body{
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}
